import React from "react";
import { graphql } from "gatsby";
import Errors from "../components/errors";
import Magazin from "../templates/magazin";

export const query = graphql`
  query MagazinQuery {
    allServices: allSanityService(sort: { fields: title }) {
      nodes {
        title
        disabled
        slug {
          current
        }
      }
    }
    magazinPreview: allSanityPost(sort: { fields: publishedAt, order: DESC }, limit: 6) {
      nodes {
        title
        slug {
          current
        }
      }
    }
    tags: allSanityCategory(sort: { fields: title }) {
      nodes {
        title
        _id
      }
    }
    magazin: allSanityPost(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        title
        slug {
          current
        }
        mainImage {
          ...SanityImage
          alt
        }
      }
    }
  }
`;

const MagazinPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return <Errors errors={errors} />;
  }

  return <Magazin data={data} />;
};

export default MagazinPage;
