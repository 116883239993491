import React, { useEffect, useState } from "react";
import { cn } from "../lib/helpers";

import { Link } from "gatsby";

import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../client-config";

import * as styles from "./magazinMain.module.css";
import * as commonStyles from "../styles/common.module.css";

function MagazinMain({ magazin = [] }) {
  return (
    <div>
      <h1 className={cn(styles.headline, commonStyles.colorAccent, commonStyles.fontButlerMedium)}>
        Magazín
      </h1>
      <ul className={styles.masonry}>
        {magazin.map((article, index) => {
          const imageData = getGatsbyImageData(article.mainImage, {}, clientConfig.sanity);
          return (
            <li key={`article_${index}`} className={styles.item}>
              <Link to={`/magazin/${article.slug.current}`}>
                <div className={styles.articleContainer}>
                  <GatsbyImage image={imageData} alt={article.mainImage?.alt} />
                  <h2
                    className={cn(
                      styles.title,
                      commonStyles.colorAccent,
                      commonStyles.fontButlerMedium
                    )}
                  >
                    {article.title}
                  </h2>
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default MagazinMain;
